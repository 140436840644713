import { MAX_NUMBER, NO_VALIDATOR_FOR_THIS_FILED, RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { StepError } from '~/state/mainAppState';
import { RentalApplicationErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateSingleFiledUseCase extends MagicUseCase {
  private validateAnnualIncome = () => {
    const income = this.getState().user.rentalApplication.application?.annualIncome;

    if (!income || income <= 0 || income > MAX_NUMBER) {
      return RentalApplicationErrorCode.InvalidAnnualIncome;
    }
  };

  private rules: Partial<Record<string, { validator: () => RentalApplicationErrorCode | undefined }>> = {
    annualIncome: { validator: this.validateAnnualIncome },
  };

  protected async runLogic(fieldName?: string) {
    const { t } = useLocalization();
    const application = this.getState().user.rentalApplication.application;

    if (!application) {
      throw new Error(t(RENTAL_APPLICATION_NOT_FOUND));
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };

    if (fieldName) {
      this.getState().user.rentalApplication.errors.singleFiled = { ...this.getState().user.rentalApplication.errors.singleFiled };

      const validator = this.rules[fieldName as keyof typeof this.rules]?.validator;
      const stepError = this.getState().user.rentalApplication.errors.singleFiled as StepError;

      if (!validator) {
        throw new Error(t(NO_VALIDATOR_FOR_THIS_FILED, { fieldName }));
      }

      stepError[fieldName] = validator();
    } else {
      this.getState().user.rentalApplication.errors.singleFiled = {};

      Object.keys(this.rules).forEach((key) => {
        const stepError = this.getState().user.rentalApplication.errors.singleFiled as StepError;
        stepError[key] = this.rules[key]?.validator();
      });
    }
  }
}
