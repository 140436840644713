import { Component, Show } from 'solid-js';
import { COMPANY_NAME, EMPLOYMENT_HISTORY, END_DATE, PHONE, POSITION, REMOVE, SALARY, START_DATE } from '~/assets/strings';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import useUseCase from '~/framework/hooks/useUseCase';
import { useErrorMessage } from '~/hooks/useErrorMessage';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableEmploymentInformation } from '~/presenters/RentalApplicationPresenter';
import { StepError } from '~/state/mainAppState';
import { ValidateEmploymentHistoryUseCase } from '~/use-cases/rental-applications/application-steps/validateEmploymentHistoryUseCase';

export interface RentalHistoryItemProps {
  index: number;
  history: PresentableEmploymentInformation;
  errors?: StepError;
  onUpdate: (item: PresentableEmploymentInformation, index: number) => void;
  onDelete: (index: number) => void;
}

export const EmploymentHistoryItem: Component<RentalHistoryItemProps> = (props) => {
  const { t } = useLocalization();
  const { getErrorMessage } = useErrorMessage();
  const { execute: validateEmploymentHistory } = useUseCase(ValidateEmploymentHistoryUseCase);

  let nameRef: HTMLInputElement | undefined;
  let phoneRef: HTMLInputElement | undefined;
  let positionRef: HTMLInputElement | undefined;
  let salaryRef: HTMLInputElement | undefined;
  let startDateRef: HTMLInputElement | undefined;
  let endDateRef: HTMLInputElement | undefined;

  const onUpdate = (fieldName: string) => {
    const history: PresentableEmploymentInformation = {
      name: nameRef?.value || '',
      phone: phoneRef?.value || '',
      position: positionRef?.value || '',
      salary: salaryRef?.value,
      startDate: startDateRef?.value,
      endDate: endDateRef?.value,
    };

    props.onUpdate(history, props.index);
    validateEmploymentHistory({ index: props.index, fieldName });
  };

  return (
    <div class="grid w-full grid-cols-2 gap-5 pb-10">
      <div class="text-md font-semibold text-text-level01">
        {t(EMPLOYMENT_HISTORY)} {props.index + 1}
        <Show when={props.index === 0}>
          <span class="text-error"> *</span>
        </Show>
      </div>
      <div class="flex items-end justify-end">
        <button
          id="rental-history-remove-employment-history"
          class="rounded-md border border-danger bg-danger-light px-2 text-danger"
          onClick={() => props.onDelete(props.index)}>
          {t(REMOVE)}
        </button>
      </div>
      <div class="col-span-2 grid grid-cols-2 gap-5">
        <LabeledTextInput
          ref={nameRef}
          label={t(COMPANY_NAME)}
          value={props.history.name}
          error={getErrorMessage(props.errors?.name)}
          onChange={() => onUpdate('name')}
          required
        />
        <LabeledTextInput
          ref={phoneRef}
          label={t(PHONE)}
          value={props.history.phone}
          error={getErrorMessage(props.errors?.phone)}
          onChange={() => onUpdate('phone')}
        />
        <LabeledTextInput
          ref={positionRef}
          label={t(POSITION)}
          value={props.history.position}
          error={getErrorMessage(props.errors?.position)}
          onChange={() => onUpdate('position')}
          required
        />
        <LabeledTextInput
          id="rental-history-salary"
          ref={salaryRef}
          label={t(SALARY)}
          value={props.history.salary || ''}
          error={getErrorMessage(props.errors?.salary)}
          onChange={() => onUpdate('salary')}
        />
        <LabeledTextInput
          id="rental-history-startDate"
          ref={startDateRef}
          label={t(START_DATE)}
          type="date"
          value={props.history.startDate}
          error={getErrorMessage(props.errors?.startDate)}
          onChange={() => {
            onUpdate('startDate');
            onUpdate('endDate');
          }}
          required
        />
        <LabeledTextInput
          id="rental-history-endDate"
          ref={endDateRef}
          label={t(END_DATE)}
          type="date"
          value={props.history.endDate}
          error={getErrorMessage(props.errors?.endDate)}
          onChange={() => onUpdate('endDate')}
        />
      </div>
    </div>
  );
};
