import { RENTAL_APPLICATION_NOT_FOUND, MAX_NUMBER } from '~/assets/strings';
import { useLocalization } from '~/hooks/useLocalization';
import { StepError } from '~/state/mainAppState';
import { EmploymentInformation, RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';
import { regexps } from '~/utils/tools';

export class ValidateEmploymentHistoryUseCase extends MagicUseCase {
  private isValidPhoneNumber = (phoneNumber: string): boolean => {
    let isValid = false;
    const digitsOnly = phoneNumber.replace(/\D+/g, '');

    if (/^(00|\+)/.test(phoneNumber)) {
      isValid = phoneNumber.length > 3;
    } else {
      isValid = digitsOnly.length === 10;
    }

    return isValid;
  };

  private validateEmployerName = (data?: EmploymentInformation) => {
    if (!data?.name || !regexps.name.test(data?.name)) {
      return RentalApplicationErrorCode.InvalidNameError;
    }
  };

  private validateEmployerPhoneNumber = (data?: EmploymentInformation) => {
    if (data?.phone && !this.isValidPhoneNumber(data?.phone)) {
      return RentalApplicationErrorCode.InvalidPhoneError;
    }
  };

  private validatePosition = (data?: EmploymentInformation) => {
    if (!data?.position || data?.position.length === 0) {
      return RentalApplicationErrorCode.InvalidPositionError;
    }
  };

  private validateSalary = (data?: EmploymentInformation) => {
    if (data?.salary && data?.salary < 0 && data?.salary > MAX_NUMBER) {
      return RentalApplicationErrorCode.InvalidSalaryError;
    }
  };

  private validateStartDate = (data?: EmploymentInformation) => {
    if (!data?.startDate || data?.startDate.length === 0 || !regexps.date.test(data?.startDate)) {
      return RentalApplicationErrorCode.InvalidStartDateError;
    }
  };

  private validateEndDate = (data?: EmploymentInformation) => {
    let { endDate, startDate } = data ?? {};

    if (!endDate) {
      return undefined;
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    endDate = endDate || '';
    startDate = startDate || '';
    const endDateObj = new Date(endDate);
    const startDateObj = new Date(startDate);

    if (endDate.length > 0 && !dateRegex.test(endDate)) {
      return RentalApplicationErrorCode.InvalidEndDateError;
    } else if (endDate.length > 0 && dateRegex.test(endDate) && dateRegex.test(startDate) && startDateObj >= endDateObj) {
      return RentalApplicationErrorCode.InvalidEndDateEarlierError;
    }
  };

  private rules: Partial<Record<string, { validator: (data: any) => RentalApplicationErrorCode | undefined }>> = {
    name: { validator: this.validateEmployerName },
    phone: { validator: this.validateEmployerPhoneNumber },
    position: { validator: this.validatePosition },
    salary: { validator: this.validateSalary },
    startDate: { validator: this.validateStartDate },
    endDate: { validator: this.validateEndDate },
  };

  protected async runLogic(params?: { index: number; filedName: string }) {
    const { t } = useLocalization();
    const application = this.getState().user.rentalApplication.application;

    if (!application) {
      throw new Error(t(RENTAL_APPLICATION_NOT_FOUND));
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };

    const employmentHistory = application.employmentHistory;

    if (!employmentHistory || employmentHistory.length === 0) {
      return;
    }

    if (!this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY]) {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] = [];
    } else {
      this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] = [
        ...(this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[]),
      ];
    }

    const histories = params?.index ? [employmentHistory.at(params.index)] : employmentHistory;

    histories.forEach((history, historyIndex) => {
      const index = params?.index ?? historyIndex;

      if (params?.filedName) {
        (this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[])[index] = {
          ...(this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[])[index],
        };

        const stepError = (this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[])[index];

        stepError[params?.filedName] = this.rules[params.filedName]?.validator(history);
      } else {
        (this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[])[index] = {};

        const stepError = (this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] as StepError[])[index];

        Object.keys(this.rules).forEach((key) => {
          const rule = this.rules[key];
          stepError[key] = rule?.validator(history);
        });
      }
    });
  }
}
