import { StepError } from '~/state/mainAppState';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class RemoveEmploymentHistoryErrorUseCase extends MagicUseCase {
  protected async runLogic(index: number) {
    const employmentHistoryErrors = [
      ...((this.getState().user.rentalApplication.errors?.[RentalApplicationSteps.WORK_HISTORY] as StepError[]) ?? []),
    ] as StepError[];

    if (!employmentHistoryErrors.length) {
      return;
    }

    employmentHistoryErrors.splice(Number(index), 1);
    this.getState().user.rentalApplication.errors[RentalApplicationSteps.WORK_HISTORY] = employmentHistoryErrors;
  }
}
